export const FLUSH_DB_DATA = "FLUSH_DB_DATA";
export const FLUSH_DB_SUCCESS = "FLUSH_DB_SUCCESS";
export const FLUSH_DB_FAILURE = "FLUSH_DB_FAILURE";

export const CONTAINER_RESTART_DATA = 'CONTAINER_RESTART_DATA';
export const CONTAINER_RESTART_SUCCESS = 'CONTAINER_RESTART_SUCCESS';
export const CONTAINER_RESTART_FAILURE = 'CONTAINER_RESTART_FAILURE';

export const NEO4J_STATUS_DATA = "NEO4J_STATUS_DATA";
export const NEO4J_STATUS_SUCCESS = "NEO4J_STATUS_SUCCESS";
export const NEO4J_STATUS_FAILURE = "NEO4J_STATUS_FAILURE";

export const RELOAD_DATA = "RELOAD_DATA";
export const RELOAD_SUCCESS = "RELOAD_SUCCESS";
export const RELOAD_FAILURE = "RELOAD_FAILURE";